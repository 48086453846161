import React from 'react'

export type IntertwinedGridItem = React.ReactNode

export interface IntertwinedGrid {
	items: [
		IntertwinedGridItem,
		IntertwinedGridItem,
		IntertwinedGridItem,
		IntertwinedGridItem,
		IntertwinedGridItem,
	]
}

export const IntertwinedGrid: React.FunctionComponent<IntertwinedGrid> = ({
	items,
}) => {
	return (
		<div className="intertwinedGrid">
			{items.map((item, i) => (
				<div className="intertwinedGrid-item" key={i}>
					<div className="intertwinedGrid-item-in">{item}</div>
				</div>
			))}
		</div>
	)
}
