import { ContentFragment } from './fragments'
import striptags from 'striptags'

export enum ContentBlockType {
	text = 'text',
	leadText = 'lead_text',
	image = 'image',
	textWithImage = 'image_text',
	html = 'html',
	gallery = 'image_list',
	productList = 'product_list',
	documentList = 'document_list',
	articleList = 'article_list',
	categoryList = 'category_list',
	highlightedBox = 'highlighted_box',
	contactForm = 'contact_form',
	videoList = 'video_listing',
	videoArticle = 'video_article',
	textColumns = 'text_columns',
	numberedColumns = 'numbered_columns',
	linkBoxes = 'link_boxes',
	linkSlider = 'link_slider',
	homepageIntertwinedCategoryList = 'homepage_intertwined_categories',
	homepageCategorySwiper = 'category_slider',
	homepageTopCategories = 'homepage_top_categories',
	homepageNews = 'homepage_news',
	homepageArticles = 'homepage_articles',
	homepageInteractiveHero = 'banner',
}

export const contentToPlainText = (content?: ContentFragment) => {
	if (!content) {
		return ''
	}
	return content.blocks
		.map((block) => {
			switch (block.type) {
				case ContentBlockType.leadText:
				case ContentBlockType.text:
				case ContentBlockType.textWithImage:
				case ContentBlockType.highlightedBox:
					return striptags(block.html ?? '')
				case ContentBlockType.html:
					return striptags(block.primaryText || '')
				case ContentBlockType.textColumns:
				case ContentBlockType.linkBoxes:
				case ContentBlockType.linkSlider:
				case ContentBlockType.numberedColumns:
					return (
						(block.primaryText || '') +
						' ' +
						block.columnList?.items
							.map(
								(item) =>
									(item.primaryText || '') +
									' ' +
									striptags(item.html ?? ''),
							)
							.join(' ')
					)
			}
		})
		.join(' ')
		.trim()
}
